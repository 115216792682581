<template>
<!-- 会员信息 -->
   <div class="bg-white radius-10 m-2 p-2">
       <h4>会员信息</h4>
       <div class="d-flex my-2">
           <span for="">姓 名</span>
           <div class="ms-auto text-muted">{{  data.user_info.name }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">级 别</label>
           <div class="ms-auto text-muted">{{ data.user_info.level.name }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">推荐人</label>
           <div class="ms-auto text-muted">{{ data.user_info.referee.name }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">注册时间</label>
           <div class="ms-auto text-muted">{{ data.user_info.created_time }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">实 名</label>
           <div class="ms-auto text-muted">{{ data.user_info.status == 0 ? '未实名' : '实名' }}</div>
       </div>
   </div>
   <!-- 会员业绩 -->
   <div class="bg-white radius-10 m-2 p-2">
       <h4>会员业绩</h4>
       <div class="d-flex my-2">
           <span for="">近1月收入</span>
           <div class="ms-auto text-muted">{{  data.achievement.month }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">总收入</label>
           <div class="ms-auto text-muted">{{ data.achievement.total_income }}</div>
       </div>
       <div class="d-flex my-2">
           <label for="">直邀人数</label>
           <div class="ms-auto text-muted">{{ data.achievement.direct_invite }}</div>
       </div>
   </div>
   <!-- 联系会员：限直推 -->
   <div class="px-3 mt-2" v-if="data.user_info.referee.id == getCookie('uid')">
       <a :href="'tel:' + data.user_info.phone" class="btn btn-sm btn-hr w-100">联 系</a>
   </div>
    
</template>

<script setup>
import { onMounted, ref } from 'vue'
import Axios from 'axios'
import { useRoute } from 'vue-router'
import { getCookie } from '@/lib/unitls'

const route = useRoute()
const data = ref({
    user_info: {
        id: 100, //用户id
        name: 'simon chen', //姓名
        phone: '180810880889', //电话
        level: {id: 1, name: 'VIP'}, //级别
        referee: {id: 100, name: 'jic sss'}, //推荐人
        created_time: '2022-02-30', //注册日期
        status: 0 //实名状态
    },
    achievement: {
        month: 2000, //近一个月收入
        total_income: 100000, //总收入
        direct_invite: 500 //直推人数
    }
})
onMounted(() => {
    // Axios.get('/api/v1/userinfo/' + route.params.id).then((res) => {
    //     if(res.data.code == 0) {
    //         data.value = res.data.data
    //     }
    // })
})
</script>

<style>

</style>